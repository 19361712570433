export const availableCertifications = [
  "Level 1 QBO ProAdvisor",
  "Level 2 QBO ProAdvisor",
  "QBO Payroll ProAdvisor",
  "CleanUp Certification",
] as const

export const availableReviews = [
  "ProAdvisor Reviews",
  "Google Reviews",
]
