import Modal from "components/Modal"
import useAccountant from "contexts/User/useAccountant"
import { AwaitableModal } from "hooks/useAwaitableModal"

const SubscribeToGym: AwaitableModal<{}, {}> = ({ open, resolve }) => {
  const user = useAccountant()
  const onSubscribe = () => {
    const sp = new URLSearchParams()
    sp.append("email", user.xpData.email)
    sp.append("first_name", user.xpData.firstName)
    sp.append("last_name", user.xpData.lastName)
    window.open("https://akadian.com/qbogym?"+sp.toString(), "_blank")
  }

  return (
    <Modal open={open} close={resolve}>
      <div
        className="bg-white rounded-2.5 divide-y divide-y-slate-200 overflow-hidden
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <main className="flex-cool p-4 items-center justify-center">
          <span className="text-2xl font-medium text-slate-900">Subscription Required</span>
          <span className="text-sm text-slate-900 mt-2">You must be an active member of the <b>QBO Gym</b> to turn this on.</span>
        </main>
        <footer className="p-4 flex items-center justify-center">
          <button onClick={onSubscribe} className="btn-primary">Click Here To Purchase</button>
        </footer>
      </div>
    </Modal>
  )
}

export default SubscribeToGym
